import {Provider} from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import "./styles/_index.scss";
import store from "./store";

ReactDOM.render(
  <Provider {...store}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </Provider>,

  document.getElementById(`root`)
);
serviceWorker.register();
