import React, { useEffect } from "react";

import Layout from "../components/app/Layout";
import Button from '../components/ui-elements/Button';

import styles from './invalid.module.scss';
import { inject, observer } from "mobx-react";

const Invalid = (props) => {
	useEffect(() => {
		checkAuth();
	}, []);

	const checkAuth = () => {
		if(!props.uiStore.userAuth){
			props.history.push('/enter');
		}
	}

	return(
		<>
			<Layout step="id">
				<div className={styles.invalid__wrapper}>
					<h2 className={styles.invalid__title}>Online verwerking <span className="highlight">niet mogelijk</span></h2>
					<p className={styles.invalid__text}>
						Het <span className="highlight">type {props.uiStore.candidate.idType} identiteitskaart</span> kunnen wij niet online verwerken. Gelieve contact met ons op te nemen en dan helpen wij u verder.
					</p>
					<div className={styles.invalid__contact}>
						<a className={styles.invalid__contact__button} href="mailto:kortrijk@thejobstore.be">
							<Button type='primary' text='mail ons' />
						</a>
						<p>of bel <a className={styles.invalid__contact__phone} href="tel:056221035">056 22 10 35</a></p>
					</div>
					
				</div>
			</Layout>
		</>
	);
};

export default inject(`uiStore`)(observer(Invalid));