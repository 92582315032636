import React from 'react';

import Button from '../ui-elements/Button';

import styles from './questions.module.scss';

const Questions = () => {
  return (
    <section className={styles.questions}>
      <div className={styles.questionsLeft}>
        <div>
          <p className={styles.questionsLeftTitle}>
            Vragen of <br /> problemen?
          </p>
          <p className={styles.questionsLeftBaseline}>
            Wij helpen u graag verder.
          </p>
        </div>
      </div>

      <div className={styles.questionsRight}>
        <p className={styles.questionsRightText}>
          Indien u vragen hebt of problemen ondervindt dan neemt u best contact
          op met The Job Store. Wij helpen u dan verder.
        </p>
        <p className={styles.questionsRightCall}>
          bel ons op
          <a className={styles.questionsRightPhone} href="tel:056221035">
            {' '}
            056 22 10 35
          </a>
        </p>
        <p className={styles.questionsRightOr}>of</p>
        <a href="mailto: kortrijk@thejobstore.be">
          <Button text="mail ons" type="primary" />
        </a>
      </div>
    </section>
  );
};

export default Questions;
