import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

import Layout from '../components/app/Layout';
import Button from '../components/ui-elements/Button';
import Input from '../components/ui-elements/Input';
import { ROUTES } from '../constants';

import styles from './enter.module.scss';

const Enter = (props) => {
  const [approved, setApproved] = useState(true);

  useEffect(() => {
    props.uiStore.checkClient();
    props.dataStore.getCountries();
    props.dataStore.getPwds();

    const id = shortid.generate();
    props.uiStore.userId = id;
    props.uiStore.roomId = id;

  }, [props.dataStore, props.uiStore, props.dataStore.pwdsData]);

  

  return (
    <Layout step="enter" next={ROUTES.personalInformation}>
      <Link to={ROUTES.home} className={styles.enter__back}>
        terug
      </Link>
      <div className={styles.enter__container}>
        <h2 className={'appTitle'}>
          Geef hier de code in die u kreeg van uw nieuwe werkgever of van the
          job store.
        </h2>

        <p className={styles.enter__text}>
          Contacteer uw werkgever of The Job Store indien u geen code ontvangen
          heeft.
        </p>

        <div className={styles.enter__input}>
          <Input
            onChange={(value) => props.uiStore.checkClient(value.trim())}
            placeholder="uw ontvangen code"
          />

          {props.uiStore.currentClient ? (
            <p>
              u gaat werken voor{' '}
              <span className={styles.enter__client}>
                {props.uiStore.currentClient}
              </span>
            </p>
          ) : null}
        </div>

        {/* <div className={styles.enter__conditions}>
          <label>
            <input type="checkbox" onChange={() => setApproved(!approved)} />
            <span>
              ik heb{' '}
              <a
                href="https://thejobstore.be/assets/docs/privacy_policy_the_job_store_2019.pdf"
                rel="noopener noreferrer"
                target="_blank"
                className={styles.link}
              >
                de voorwaarden
              </a>{' '}
              gelezen en goedgekeurd.
            </span>
          </label>
        </div> */}
        <div className={styles.enter__conditions}>
          <p>Uw persoonsgegevens worden verwerkt volgens onze <a href="https://thejobstore.be/privacy-voorwaarden" target="_blank">privacyverklaring</a></p>
        </div>

        <Link to={ROUTES.personalInformation}>
          <Button
            text="starten"
            type="primary"
            state={
              props.uiStore.userAuth === true && approved === true
                ? false
                : true
            }
          />
        </Link>
      </div>
    </Layout>
  );
};

export default inject(`uiStore`, `dataStore`)(observer(Enter));
