import React, { Component } from 'react';

import styles from './input.module.scss';

class Input extends Component {
  constructor(props) {
		super(props);
		this.state = {
			value: this.props.value
		}

		this.handleChange = this.handleChange.bind(this);
  };

	handleChange = e => {
		this.props.onChange(e.currentTarget.value);
	}

	handleBlur = e => {
		this.props.onBlur(e.currentTarget.value);
	}

	render() {
		return (
			<>
				<input 
					onChange={this.handleChange} 
					type={this.props.type ? this.props.type : 'text'} 
					name={this.props.name} 
					className={styles.input} 
					placeholder={this.props.placeholder} 
					defaultValue={this.state.value}
					required={this.props.required}
					onBlur={this.props.onBlur ? this.handleBlur :null}
				/>
			</>
		);
	}
}

export default Input;