import React, { useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';

import styles from './mobile.module.scss';
import { inject, observer } from 'mobx-react';
import ScanFront from '../components/app/mobile/ScanFront';
import ScanBack from '../components/app/mobile/ScanBack';
import LoadingScreen from '../components/app/mobile/LoadingScreen';

const MobileScan = (props) => {
  const [frontValid, setFrontValid] = useState(false);
  const [imgSourceFront, setImageSourceFront] = useState();
  const [imgSourceBack, setImageSourceBack] = useState();
  const [sent, setSent] = useState(false);
  const [recieved, setRecieved] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    props.uiStore.socket.on('getImages', () => setRecieved(true));

    const urlId = props.location.pathname.split('/')[2];
    setUserId(urlId);
    props.uiStore.joinSocket(urlId);
  }, []);

  const onHandleUpload = (e, side) => {
    const file = e.currentTarget.files[0];

    handleImageUpload(file, side);
  };

  const handleImageUpload = async (imageFile, side) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options); // compress file
      const base64 = await imageCompression.getDataUrlFromFile(compressedFile); // get base64 of compressed file

      await addImageSrc(base64, side);
    } catch (error) {}
  };

  const addImageSrc = (file, side) => {
    if (side === 'front') {
      setImageSourceFront(file);
    } else {
      setImageSourceBack(file);
    }
  };

  const handleSendImages = () => {
    setSent(true);

    props.uiStore
      .sendImages(imgSourceFront, imgSourceBack, userId)
      .then((r) => console.log('return: ', r));
  };

  return (
    <>
      <div className={styles.mobile__wrapper}>
        {recieved ? (
          <>
            <h2
              className={`${styles.mobile__title} ${styles.mobile__titleSent}`}
            >
              <span className={'highlight'}>Klaar!</span>
            </h2>
            <img
              className={styles.mobile__image}
              src="../assets/scanned_verification.svg"
              alt="Scanned illustration"
            />
            <p className={`${styles.mobile__text} ${styles.mobile__textSent}`}>
              Uw afbeeldingen werden doorgestuurd naar de applicatie. U kunt uw
              inschrijving verder voltooien.
            </p>
          </>
        ) : (
          <>
            <h2 className={styles.mobile__title}>
              <span className={'highlight'}>Scan uw</span> identiteitskaart
            </h2>
            <p className={styles.mobile__text}>
              Zorg ervoor dat uw kaart op beide afbeeldingen volledig zichtbaar
              en gemakkelijk leesbaar is.
            </p>

            {/* ========== previews ========== */}
            {!frontValid ? (
              <div
                className={styles.mobile__preview}
                style={{ backgroundImage: `url(${imgSourceFront})` }}
              ></div>
            ) : (
              <div className={styles.mobile__preview__wrapper}>
                <div
                  className={`
										${styles.mobile__preview}
										${frontValid ? styles.mobile__preview__back : ''}
										`}
                  style={{ backgroundImage: `url(${imgSourceFront})` }}
                ></div>
                <div
                  className={`${styles.mobile__preview} ${
                    styles.mobile__previewSecond
                  } ${frontValid ? styles.mobile__preview__back : ''}`}
                  style={{ backgroundImage: `url(${imgSourceBack})` }}
                ></div>
                <div
                  className={`
											${styles.mobile__preview__wrapper__space}
											${frontValid ? styles.mobile__preview__back : ''}
										`}
                >
                  .
                </div>
              </div>
            )}

            {!props.uiStore.candidate.idImages && sent ? (
              <LoadingScreen />
            ) : null}

            {!frontValid ? (
              <ScanFront
                imgSourceFront={imgSourceFront}
                onHandleUpload={(e, side) => onHandleUpload(e, side)}
              />
            ) : (
              <ScanBack
                imgSourceBack={imgSourceBack}
                onHandleUpload={(e, side) => onHandleUpload(e, side)}
              />
            )}

            {imgSourceFront && !frontValid ? (
              <button
                onClick={() => setFrontValid(true)}
                className={`${styles.mobile__button} ${styles.mobile__buttonPrimary}`}
              >
                achterkant scannen
              </button>
            ) : null}

            {frontValid && imgSourceBack ? (
              <button
                onClick={() => handleSendImages()}
                className={`${styles.mobile__button} ${styles.mobile__buttonPrimary}`}
              >
                Klaar met scannen
              </button>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default inject(`uiStore`, `dataStore`)(observer(MobileScan));
