import React, { useState } from 'react';

import styles from './countries.module.scss';

const Countries = (props) => {
  const [selected, setSelected] = useState();

  const handleSelectCountry = (e) => {
    props.onSelect(e);
    setSelected(e.currentTarget.value);
  };

  return (
    <ul className={styles.countries}>
      {props.countries.map((country) => (
        <li key={country.translations.nld.common ?? country.name}>
          <label
            className={`
						${styles.country}
						${
              selected === (country.translations.nld.common ?? country.name) ||
              props.selected === (country.translations.nld.common ?? country.name).toLowerCase()
                ? styles.active
                : ''
            }
					`}
          >
            <input
              type="radio"
              name="countries"
              value={country.translations.nld.common ?? country.name}
              onChange={(e) => handleSelectCountry(e)}
              selected={props.selected === (country.translations.nld.common ?? country.name) ? true : false}
            />
            <span>{country.translations.nld.common ?? country.name}</span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default Countries;
