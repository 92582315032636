import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Button from '../ui-elements/Button';

import styles from './about.module.scss';

const About = () => {

	return (
		<section id="about" className={styles.about}>
			<h2 className={styles.title}><span className={styles.highlight}>vergemakkelijk</span> uw inschrijving</h2>
			<p className={styles.aboutText}>Wij hebben deze applicatie ontwikkeld om uw inschrijving vlotter en gemakkelijker te laten verlopen. Hier kunt u als gebruiker uw gegevens op een simpele en veilige manier met ons delen zonder dat u hiervoor hoeft langs te komen. </p>
			<AnchorLink href="#steps">
				<Button text="ontdek hoe" type="primary" />
			</AnchorLink>
			
		</section>
	);
}

export default About;