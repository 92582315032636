import React from 'react';

import styles from '../../../containers/mobile.module.scss';

const LoadingScreen = (props) => {
	return(
		<div className={styles.loadingScreen}>
			<div className={styles.loadingScreen__wrapper}>
				<p>Afbeeldingen worden doorgestuurd</p>
				<div className={styles.loadingScreen__loader}></div>
			</div>
		</div>
	);
}

export default LoadingScreen;