import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../ui-elements/Button';
import Steps from '../ui-elements/Steps';

import styles from './layout.module.scss';
import { inject, observer } from 'mobx-react';
import { ROUTES } from '../../constants';

const Layout = (props) => {
  return (
    <div className={styles.layout}>
      <div className={styles.layout__cancel}>
        <a href="/">inschrijving stoppen</a>
      </div>
      <div className={styles.layout__logo}>
        <img
          src="assets/thejobstore_logo.svg"
          alt="The Job Store logo"
          draggable="false"
        />
      </div>
      <div className={styles.layout__container}>
        {props.step !== 'enter' && props.step !== 'done' ? (
          <div className={styles.layout__steps}>
            <Steps current={props.step} />
          </div>
        ) : null}

        <div className={styles.layout__content}>{props.children}</div>

        {props.step !== 'enter' ? (
          <div className={styles.layout__navButtons}>
            {props.previous ? (
              <Link to={props.previous}>
                <Button text="terug" type="secondary" />
              </Link>
            ) : null}

            {props.next ? (
              <Link to={props.next}>
                <Button
                  text="volgende"
                  type="primary"
                  state={props.nextState ? props.nextState : false}
                />
              </Link>
            ) : null}

            {props.step === 'control' ? (
              <Link to={ROUTES.done}>
                <Button
                  onClick={() => props.uiStore.sendMail()}
                  text="klaar"
                  type="primary"
                  state={props.nextState ? props.nextState : false}
                />
              </Link>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default inject(`uiStore`)(observer(Layout));
