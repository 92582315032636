import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import Layout from '../components/app/Layout';
import StatusTypes from '../components/app/status/StatusTypes';

import styles from './status.module.scss';
import { ROUTES } from '../constants';

const Status = (props) => {
  const [validate, setValidate] = useState(false);
  const [selected, setSelected] = useState();
  const statusList = ['jobstudent(e)', 'werkstudent(e)', 'werkne(e)m(st)er', 'flexi'];

  const statusText = {
    'jobstudent(e)':
      'Je gaat voltijds naar school. Je mag tot 475 uren per jaar werken in het statuut van jobstudent.',
    'werkstudent(e)':
      'Je hebt als student je 475 uren werken per jaar opgebruikt. Je bent ingeschreven als student op school. Je kan werken als werkstudent.',
    'werkne(e)m(st)er':
      'Je bent ingeschreven als werkzoekende of werkt momenteel. Je bent dus geen student meer.',
    flexi:
      'Je was 9 maanden geleden aan het werk met een contract van minimum 4/5 de tewerkstelling.',
  };

  useEffect(() => {
    checkAuth();
    if (props.uiStore.candidate.status) {
      setValidate(true);
    }
  }, []);

  const checkAuth = () => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }
  };

  const onSelectStatus = (r) => {
    props.uiStore.candidate.status = r;
    setSelected(r);

    setValidate(true);
  };

  return (
    <>
      <Layout
        step="status"
        previous={ROUTES.uploadChoice}
        next={
          selected === 'jobstudent' ||
          props.uiStore.candidate.status === 'jobstudent'
            ? ROUTES.student
            : ROUTES.control
        }
        nextState={!validate}
      >
        <div className={styles.status__wrapper}>
          <h2 className={styles.status__title}>Uw statuut</h2>
          <div>
            <StatusTypes
              onSelect={(r) => onSelectStatus(r)}
              selected={props.uiStore.candidate.status}
              types={statusList}
            />
          </div>
          <div>
            <p className={styles.status__text}>{statusText[selected]}</p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`)(observer(Status));
