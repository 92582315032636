import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Layout from '../components/app/Layout';
import Button from '../components/ui-elements/Button';

import styles from './uploadChoice.module.scss';
import { ROUTES } from '../constants';

const UploadChoice = (props) => {
  useEffect(() => {
    checkAuth();
    props.uiStore.step = Object.keys(ROUTES).indexOf('uploadChoice');
  }, []);

  const checkAuth = () => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }
  };

  return (
    <>
      <Layout step="id" previous={ROUTES.idType}>
        <div className={styles.choice__wrapper}>
          <h2 className={styles.choice__title}>Identiteitskaart uploaden</h2>
          <div className={styles.choice__buttons}>
            <Link className={styles.choice__button} to={ROUTES.idUpload}>
              <div className={styles.choice__button__content}>
                <img src="assets/upload_web.svg" alt="" />
                <p className={styles.choice__button__text}>
                  Afbeelding uploaden
                </p>
              </div>
            </Link>
            <Link
              className={`${styles.choice__button} ${styles.choice__button__mobileHidden}`}
              to={ROUTES.idScan}
            >
              <div className={styles.choice__button__content}>
                <img src="assets/scan_mobile.svg" alt="" />
                <p className={styles.choice__button__text}>
                  Kaart scannen met smartphone
                </p>
              </div>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`)(observer(UploadChoice));
