import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Button from '../ui-elements/Button';

import styles from './header.module.scss';
import { ROUTES } from '../../constants';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.header__right}>
        <h1 className={styles.title}>
          <span className="highlight">Payroll</span> tool
        </h1>
        <p className={styles.baseline}>
          by{' '}
          <span className={styles.TJS}>
            The <span className="highlight">Job</span> Store
          </span>
        </p>
        <p className={styles.text}>
          vergemakkelijk uw inschrijving bij uw werkgever
        </p>
        <div>
          <Link to={ROUTES.enter}>
            <Button
              text="mij inschrijven"
              type="primary"
              classes="margin-right-1x"
            />
          </Link>
          <AnchorLink href="#about">
            <Button text="meer informatie" type="secondary" />
          </AnchorLink>
        </div>
      </div>
      <div className={styles.header__left}>
        <img
          src="assets/header_illustration.svg"
          alt="Illustration of a woman"
        />
      </div>
    </header>
  );
};

export default Header;
