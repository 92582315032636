export const ROUTES = {
	enter: `/enter`,
	personalInformation: '/personal-info',
	nationality: '/nationality',
	idType: '/id-type',
	uploadChoice: '/upload-choice',
	idUpload: '/upload',
	idScan: '/scan',
	mobileScan: '/mobile-scan',
	status: '/status',
	student: '/student',
	control: '/controle',
	done: '/done',
	home: `/`,
	invalidType: `/invalid-type`,
	dropzone: `/dropzone`,
};