import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { ROUTES } from '../constants';

import Home from './Home';
import Enter from './Enter';
import Personal from './Personal';
import Nationality from './Nationality';
import IdType from './IdType';
import Invalid from './Invalid';
import UploadChoice from './UploadChoice';
import IdUpload from './IdUpload';
import Status from './Status';
import Student from './Student';
import Control from './Control';
import Done from './Done';
import Scan from './Scan';
import MobileScan from './MobileScan';

function App(props) {
  return (
    <>
      <main>
        <Switch>
          <Route path={ROUTES.home} exact strict component={Home} />
          <Route path={ROUTES.enter} component={Enter} />
          <Route path={ROUTES.personalInformation} component={Personal} />
          <Route path={ROUTES.nationality} component={Nationality} />
          <Route path={ROUTES.idType} component={IdType} />
          <Route path={ROUTES.invalidType} component={Invalid} />
          <Route path={ROUTES.uploadChoice} component={UploadChoice} />
          <Route path={ROUTES.idUpload} component={IdUpload} />
          <Route path={ROUTES.idScan} component={Scan} />
          <Route path={ROUTES.mobileScan} component={MobileScan} />
          <Route path={ROUTES.status} component={Status} />
          <Route path={ROUTES.student} component={Student} />
          <Route path={ROUTES.control} component={Control} />
          <Route path={ROUTES.done} component={Done} />
          <Route component={Home} /> {/* 404 redirect to homepage */}
        </Switch>
      </main>
    </>
  );
}

export default inject(`uiStore`)(observer(App));
