import React from 'react';

import styles from '../../../containers/mobile.module.scss';


const ScanBack = (props) => {
	return(
		<div>
			<label className={`
					${styles.mobile__button} 
					${!props.imgSourceBack ? styles.mobile__buttonPrimary : styles.mobile__buttonSecondary}
				`} 
				htmlFor="scan"
			>
				{ !props.imgSourceBack ? 'achterkant scannen' : 'opnieuw proberen' }
			</label>
			<input 
				onChange={(e) => props.onHandleUpload(e, 'back')}
				className={styles.mobile__inputDefault} 
				type="file" 
				accept="image/*;capture=camera" 
				id="scan" 
			/>
		</div>
	);
}

export default ScanBack;