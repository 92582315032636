import { decorate } from 'mobx';

class ConnectionStore {
  connections = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.socket = rootStore.uiStore.socket;
    this.socket.on('getImages', (data) => this.getImages(data));
    this.socket.on('joined', this.joinedRoom);
  }

  getImages = (data) => {
    // check if id in data is same as userId in the store
    if (data[2] === this.rootStore.uiStore.userId) {
      data.pop(); // remove id before adding to images
      this.rootStore.uiStore.candidate.idImages = data;
    }
  };

  joinedRoom = () => {};
}

decorate(ConnectionStore, {});

export default ConnectionStore;
