import React, { useState, useEffect } from 'react';

import styles from './personalForm.module.scss';
import Input from '../../ui-elements/Input';
import { inject, observer } from 'mobx-react';

const ContactInfoForm = (props) => {
  const [country, setCountry] = useState();
  const [validMail, setValidMail] = useState();
  const [emptyError, setEmptyError] = useState(false);

  useEffect(() => {
    setValidation();
  }, [validMail]);

  useEffect(() => {
    if (
      props.uiStore.candidate.contact.email &&
      props.uiStore.candidate.contact.phone
    ) {
      props.onValidate(true);
    } else {
      props.onValidate(false);
    }
    props.uiStore.candidate.contact.code = 'bel +32';
  }, []);

  const handleInputForm = (input, field) => {
    // build candidate obj
    switch (field) {
      case 'email':
        props.uiStore.candidate.contact.email = input;
        break;
      case 'code':
        props.uiStore.candidate.contact.code = input.currentTarget.value;
        break;
      case 'phone':
        props.uiStore.candidate.contact.phone = input;
        break;
    }

    setValidation();
  };

  const handleBlurmail = (e) => {
    checkMailValidation(e);
    handleOnBlurRequired(e);
  };

  const checkMailValidation = (mail) => {
    /*eslint no-useless-escape: "off"*/
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(mail)) {
      // this is a valid email address
      setValidMail(true);
    } else {
      // invalid email, maybe show an error to the user.
      setValidMail(false);
    }
  };

  const setValidation = () => {
    if (
      validMail &&
      props.uiStore.candidate.contact.email &&
      props.uiStore.candidate.contact.phone
    ) {
      props.onValidate(true);
    } else {
      props.onValidate(false);
    }
  };

  const handleOnBlurRequired = (e) => {
    if (!e || e === '') {
      setEmptyError(true);
    } else {
      setEmptyError(false);
    }
  };

  return (
    <section className={styles.form__wrapper}>
      <fieldset className={styles.form}>
        <h2 className={styles.form__title}>Contact gegevens</h2>

        {emptyError ? (
          <p className={styles.form__error}>
            Zorg ervoor dat alle verplichte velden (*) ingevuld zijn.
          </p>
        ) : null}

        {validMail === false ? (
          <p className={styles.form__error}>Dit is geen correct email adres.</p>
        ) : null}

        <div className={styles.form__row}>
          <Input
            placeholder="E-mail*"
            onChange={(input) => handleInputForm(input, 'email')}
            onBlur={(e) => handleBlurmail(e)}
            value={props.uiStore.candidate.contact.email}
            name={'email'}
            type={'email'}
            required={true}
          />
        </div>

        <div className={styles.form__row}>
          <div className={styles.form__row}>
            <select
              className={styles.selectCode}
              name="codes"
              id="codes"
              value={
                props.uiStore.candidate.contact.code
                  ? props.codes.find(
                      (i) => i === props.uiStore.candidate.contact.code
                    )
                  : props.codes.find((i) => i === 'bel +32')
              }
              onChange={(input) => handleInputForm(input, 'code')}
            >
              {props.codes.map((code, i) => (
                <option key={i} value={code}>
                  {code}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.form__row__100}>
            <Input
              placeholder="Telefoon*"
              onChange={(input) => handleInputForm(input, 'phone')}
              onBlur={(e) => handleOnBlurRequired(e)}
              value={props.uiStore.candidate.contact.phone}
              name={'phone'}
              required={true}
              type={'number'}
            />
          </div>
        </div>
      </fieldset>
    </section>
  );
};

export default inject(`uiStore`)(observer(ContactInfoForm));
