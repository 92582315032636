import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../ui-elements/Button';
import styles from './steps.module.scss';

const Steps = () => {

  return (
    <section id="steps" className={styles.steps}>
      <div className={styles.stepsIllustration}>
        <img
          className={styles.stepsIllustration__desktop}
          src="/assets/steps_illustration.svg"
          alt="illustration of a man reading the steps"
        />
        <img
          className={styles.stepsIllustration__mobile}
          src="/assets/steps_illustration_mobile.svg"
          alt="illustration of the steps"
        />
      </div>
      <div className={styles.stepsRight}>
        <ul className={styles.stepsList}>
          <li className={styles.stepsItem}>
            <h3 className={styles.stepTitle}>.01 Persoonlijke gegevens</h3>
            <p className={styles.stepsItemText}>
              We beginnen bij het begin, de persoonlijke gegevens. Hier vult u
              uw naam, voornaam, woonplaats en contact gegevens in.
            </p>
          </li>

          <li className={styles.stepsItem}>
            <h3 className={styles.stepTitle}>.02 Nationaliteit</h3>
            <p className={styles.stepsItemText}>
              Om te weten welk soort identiteitskaart u heeft willen we graag uw
              nationaliteit vragen.
            </p>
          </li>

          <li className={styles.stepsItem}>
            <h3 className={styles.stepTitle}>
              .03 Copy van uw identiteitskaart
            </h3>
            <p className={styles.stepsItemText}>
              U kunt gemakkelijk een foto van uw identiteitskaart maken via de
              tool of zelf een afbeelding uploaden.
            </p>
          </li>

          <li className={styles.stepsItem}>
            <h3 className={styles.stepTitle}>.04 Statuut</h3>
            <p className={styles.stepsItemText}>
              De volgende stap is het aanduiden van uw statuut als werkne(e)m(st)er. De
              opties hier zijn gewone werkne(e)m(st)er, jobstudent(e), werkstudent(e) of
              flexi.{' '}
            </p>
          </li>
        </ul>
        <Link className={styles.steps__button} to="/enter">
          <Button text="mij inschrijven" type="primary" />
        </Link>
      </div>
    </section>
  );
};

export default Steps;
