import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../ui-elements/Button';

import styles from './navbar.module.scss';
import { ROUTES } from '../../constants';

const Nav = () => {

	return (
		<nav className={styles.navbar}>
			<div className={styles.navbar__wrapper}>
				<div>
					<img src="assets/thejobstore_logo.svg" alt="The Job Store logo"/>
				</div>
				<div>
				<Link to={ROUTES.enter}>
						<Button text="mij inschrijven" type="primary" />
					</Link>
				</div>
			</div>
		</nav>
	);
}

export default Nav;