import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import shortid from 'shortid';

import Layout from '../components/app/Layout';

import styles from './scan.module.scss';
import { inject, observer } from 'mobx-react';
import { ROUTES } from '../constants';

const Scan = (props) => {
  const [uniqueId, setUniqueId] = useState();

  // http://192.168.0.155:3000/mobile-scan/${uniqueId}
  // https://payroll.thejobstore.be/mobile-scan/${uniqueId}

  useEffect(() => {
    checkAuth();

    const generatedId = props.uiStore.userId;
    setUniqueId(generatedId);

    props.uiStore.joinSocket(generatedId);

    if (uniqueId) {
      props.uiStore.roomId = uniqueId;
    }
  }, props.uiStore.step);

  const checkAuth = () => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }
  };

  return (
    <>
      <Layout
        step="id"
        previous={ROUTES.uploadChoice}
        next={ROUTES.status}
        nextState={props.uiStore.candidate.idImages ? false : true}
      >
        <div className={styles.scan__wrapper}>
          <h2 className={styles.scan__title}>
            Uw identiteitskaart <span className="highlight">scannen</span>
          </h2>

          {props.uiStore.candidate.idImages ? (
            <>
              <p className={styles.scan__succes}>
                Uw afbeeldingen werden correct doorgestuurd. U kunt uw
                inschrijving nu verder voltooien.
              </p>
              <div className={styles.scan__previews}>
                {props.uiStore.candidate.idImages.map((img) => (
                  <div key={img} className={styles.scan__previews__card}>
                    <img src={img} alt="id card scan" width="400" />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <section className={styles.scan__section}>
              <p className={styles.scan__section__text}>
                Scan onderstaande qr-code met uw smartphone en open de
                bijhorende webpagina.
              </p>
              <div className={styles.scan__qrWrapper}>
                <QRCode
                  value={`https://payroll.thejobstore.be/mobile-scan/${uniqueId}`}
                  size={200}
                />
                <p>{uniqueId}</p>
              </div>
            </section>
          )}
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`, `dataStore`)(observer(Scan));

// value={`https://payroll.thejobstore.be/mobile-scan/${uniqueId}`}

