class Api {
  constructor(entity) {
    this.entity = entity;
    this.baseUrl = 'https://sea-turtle-app-5y27a.ondigitalocean.app/api';
    // this.baseUrl = 'http://localhost:4000/api';
  }

  create = async (obj) => {
    await fetch(
      `${this.baseUrl}/${this.entity}`,
      this.getOptions(`post`, obj)
    ).then(function (response) {
      if (!response) {
        throw new Error('HTTP status ' + response.status);
      }
      return response.json();
    });
  };

  getOptions = (method, body = null, upload = false) => {
    const options = {
      mode: 'cors',
      method: method.toUpperCase(),
    };

    if (!upload) {
      options.headers = {
        'content-type': `application/json`,
        'Access-Control-Allow-Origin': '*',
      };
    }
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  };
}

export default Api;
