import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import Layout from '../components/app/Layout';
import Button from '../components/ui-elements/Button';

import styles from './done.module.scss';

const Done = (props) => {
  let redirectTimeout;

  useEffect(() => {
    redirectHome(5000);
  });

  const redirectHome = (delay) => {
    redirectTimeout = setTimeout(function () {
      window.location.href = 'http://www.thejobstore.be/';
    }, delay);
  };

  return (
    <>
      <Layout step="done">
        <div className={styles.done__wrapper}>
          <img
            className={styles.done__image}
            src="assets/application_sent.svg"
            alt="person standing next to sent envelope"
          />
          <h2 className={styles.done__title}>
            Dank u wel voor uw inschrijving bij{' '}
            <span className="highlight">The Job Store!</span>
          </h2>
          <p className={styles.done__text}>
            Wij gaan zo snel mogelijk voor u aan de slag.
          </p>
          <a
            className={styles.done__webButton}
            href="https://www.thejobstore.be"
          >
            <Button text="naar de website" type="primary" />
          </a>
          <Link
            to={'/'}
            onClick={() => clearTimeout(redirectTimeout)}
            className="link"
          >
            terug naar home
          </Link>
        </div>
        <div className={styles.done__countdown}>
          <CountdownCircleTimer
            isPlaying
            duration={5}
            size="50"
            strokeWidth="6"
            colors={[['#EAAA00', 1]]}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`, `dataStore`)(observer(Done));
