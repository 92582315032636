import React from 'react';

import styles from './footer.module.scss';

const Footer = () => {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.footerItem}>
            <h3 className={styles.footerWrapperTitle}>Bezoek ons</h3>
            <a className={styles.footerUrl} href="https://www.thejobstore.be">
              thejobstore.be
            </a>
            <p className={styles.footerTJS}>The Job Store</p>
            <address className={styles.footerAddress}>
              Schouwburgplein 9<br />
              8500 Kortrijk
            </address>
          </div>
          <div className={styles.footerItem}>
            <h3 className={styles.footerWrapperTitle}>Contacteer ons</h3>
            <ul>
              <li>
                <a
                  className={styles.footerUrl}
                  href="mailto:info@thejobstore.be"
                >
                  info@thejobstore.be
                </a>
              </li>
              <li>
                <a className={styles.footerUrl} href="tel:056221035">
                  {' '}
                  056 22 10 35
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.footerItem}>
            <h3 className={styles.footerWrapperTitle}>Volg ons</h3>
            <ul>
              <li>
                <a
                  className={styles.footerSocial}
                  href="https://www.facebook.com/TheJobStoreInterim/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className={styles.footerSocial}
                  href="https://www.instagram.com/thejobstore/?hl=nl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className={styles.footerSocial}
                  href="https://www.linkedin.com/company/thejobstore/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Linkedin
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.footerItem}>
            <img
              className={styles.footerLogo}
              src="assets/thejobstore_logo.svg"
              alt="The job store logo"
            />
          </div>
        </div>
      </footer>
      <div className={styles.footer__name}>
        <div className={styles.footerWrapper}>
          <div>
            <a
              href="https://thejobstore.be/algemene-voorwaarden"
              target="_blank"
            >
              voorwaarden
            </a>
            &nbsp;&nbsp;
            <a
              href="https://thejobstore.be/privacy-voorwaarden"
              target="_blank"
            >
              privacybeleid
            </a>
          </div>
          <p>Erkenningsnummers: VG.2269/U — W.DISP.1494 — 20083-406-20191122</p>
          <p>
            ©{new Date().getFullYear()} The Job Store, made by{' '}
            <a
              className={styles.footer__name__me}
              href="http://www.thisisme.be"
              target="_blank"
            >
              me.
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
