import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import Layout from '../components/app/Layout';

import styles from './idUpload.module.scss';
import { ROUTES } from '../constants';

const IdUpload = (props) => {
  const maxFileSize = 1500000;
  const [files, setFiles] = useState([]);
  const [imageSources, setImageSources] = useState([]);
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }

    if (props.uiStore.candidate.idImages) {
      setImageSources(props.uiStore.candidate.idImages);

      if (props.uiStore.candidate.idImages.length >= 2) {
        setValidate(true);
      }
    }

    if (props.uiStore.candidate.idImages) {
      setFiles(props.uiStore.candidate.idImages);
    }
  }, [props.history, props.uiStore.candidate.idImages, props.uiStore.userAuth]);

  const onDrop = (input) => {
    const newList = files.concat(input);
    setFiles(files);

    newList.forEach((file) => handleImageUpload(file));

    updateCandidate(newList);
  };

  const handleImageUpload = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options); // compress file
      const base64 = await imageCompression.getDataUrlFromFile(compressedFile); // get base64 of compressed file

      await addImageSrc(base64);
    } catch (error) {}
  };

  const addImageSrc = (file) => {
    const newSources = imageSources.concat(file);
    setImageSources(newSources);

    updateCandidate(newSources);
  };

  const onRemove = (e) => {
    const list = imageSources;
    const removeIndex = list
      .map(function (item) {
        return item;
      })
      .indexOf(e);
    list.splice(removeIndex, 1);
    setImageSources([...list]);

    updateCandidate([...list]);
  };

  const updateCandidate = (data) => {
    props.uiStore.candidate.idImages = data;

    if (data.length >= 2) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };

  return (
    <>
      <Layout
        step="id"
        previous={ROUTES.uploadChoice}
        next={ROUTES.status}
        nextState={!validate}
      >
        <div className={styles.upload__wrapper}>
          <h2 className={styles.upload__title}>Identiteitskaart uploaden</h2>
          <p className={styles.upload__text}>
            Gelieve van zowel de voorkant als de achterkant van uw
            identiteitskaart een duidelijke en leesbare afbeelding te uploaden. (2 afbeeldingen verplicht)
          </p>

          <div>
            <Dropzone
              onDrop={(file) => onDrop(file)}
              previewsContainer="dropzone-previews"
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className={styles.upload__section} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className={styles.upload__section__text}>
                      Klik hier om bestanden te selecteren <br /> of sleep uw
                      bestanden hier naartoe. (Enkel afbeeldingen: jpg, png)
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className={styles.upload__section__previewList}>
            {imageSources
              ? imageSources.map((src) => (
                  <div className={styles.upload__section__preview} key={src}>
                    <img
                      className={styles.upload__section__preview__image}
                      src={src}
                      alt=""
                      width="250"
                    />
                    <button
                      className={styles.upload__section__preview__remove}
                      onClick={() => onRemove(src)}
                    >
                      <span>
                        <img
                          src="assets/remove_img.svg"
                          height="50"
                          alt="remove uploaded"
                        />
                      </span>
                    </button>
                  </div>
                ))
              : null}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`)(observer(IdUpload));
