import React, { useEffect, useState } from 'react';

import styles from './personalForm.module.scss';
import Input from '../../ui-elements/Input';
import { inject, observer } from 'mobx-react';
const iban = require('iban');

const PersonalForm = (props) => {
  const [emptyError, setEmptyError] = useState(false);
  const [validIban, setValidIban] = useState(true);

  useEffect(() => {
    if (
      props.uiStore.candidate.personal.firstName &&
      props.uiStore.candidate.personal.lastName &&
      props.uiStore.candidate.personal.street &&
      props.uiStore.candidate.personal.nr &&
      props.uiStore.candidate.personal.zip &&
      props.uiStore.candidate.personal.place && 
      props.uiStore.candidate.personal.accountNr
    ) {
      props.onValidate(true);
    } else {
      props.onValidate(false);
    }
  }, [props]);

  const handleInputForm = (input, field) => {
    setEmptyError(false);

    // build candidate obj
    switch (field) {
      case 'firstName':
        props.uiStore.candidate.personal.firstName = input.trim();
        break;
      case 'lastName':
        props.uiStore.candidate.personal.lastName = input.trim();
        break;
      case 'street':
        props.uiStore.candidate.personal.street = input.trim();
        break;
      case 'nr':
        props.uiStore.candidate.personal.nr = input.trim();
        break;
      case 'bus':
        props.uiStore.candidate.personal.bus = input.trim();
        break;
      case 'zip':
        props.uiStore.candidate.personal.zip = input.trim();
        break;
      case 'place':
        props.uiStore.candidate.personal.place = input.trim();
        break;
      case 'accountNr':
        props.uiStore.candidate.personal.accountNr = iban.isValid(input.trim()) ? input.trim() : null;
        break;
      case 'maritalStatus':
        props.uiStore.candidate.personal.maritalStatus =
          input.currentTarget.value;
        break;
      case 'kids':
        props.uiStore.candidate.personal.kids = input.currentTarget.value;
        break;
      case 'kidsAmount':
        props.uiStore.candidate.personal.kidsAmount = input.trim();
        break;
    }

    if (
      emptyError === false &&
      props.uiStore.candidate.personal.firstName &&
      props.uiStore.candidate.personal.lastName &&
      props.uiStore.candidate.personal.street &&
      props.uiStore.candidate.personal.nr &&
      props.uiStore.candidate.personal.zip &&
      props.uiStore.candidate.personal.place &&
      props.uiStore.candidate.personal.accountNr &&
      props.uiStore.candidate.personal.maritalStatus &&
      props.uiStore.candidate.personal.kids
    ) {
      props.onValidate(true);
    } else {
      props.onValidate(false);
    }
  };

  const handleOnBlurRequired = (e, ibanInput) => {
    if (!e || e === '') {
      setEmptyError(true);
    } else {
      setEmptyError(false);
    }

    if (ibanInput && !iban.isValid(e)) {
      setEmptyError(false);
      setValidIban(false)
    } else {
      setValidIban(true)
    }
  };

  return (
    <form className={styles.form__wrapper}>
      <fieldset className={styles.form}>
        <h2 className={styles.form__title}>Persoonlijke gegevens</h2>

        {emptyError ? (
          <p className={styles.form__error}>
            Zorg ervoor dat alle verplichte velden (*) ingevuld zijn.
          </p>
        ) : null}
        {!validIban ? (
          <p className={styles.form__error}>
            [ongeldig rekeningnummer] Controleer uw ingegeven rekeningnummer.
          </p>
        ) : null}

        <div className={styles.form__row}>
          <Input
            placeholder="Voornaam*"
            onChange={(input) => handleInputForm(input, 'firstName')}
            onBlur={(e) => handleOnBlurRequired(e)}
            value={props.uiStore.candidate.personal.firstName}
            name={'firstname'}
            required={true}
          />

          <Input
            placeholder="Naam*"
            onChange={(input) => handleInputForm(input, 'lastName')}
            onBlur={(e) => handleOnBlurRequired(e)}
            value={props.uiStore.candidate.personal.lastName}
            name={'lastname'}
            required={true}
          />
        </div>

        <div className={styles.form__row}>
          <div className={`${styles.form__row__60} margin-right-1x`}>
            <Input
              placeholder="Straat*"
              onChange={(input) => handleInputForm(input, 'street')}
              onBlur={(e) => handleOnBlurRequired(e)}
              value={props.uiStore.candidate.personal.street}
              name={'street'}
              required={true}
            />
          </div>
          <div className={`${styles.form__row__20} margin-right-1x`}>
            <Input
              placeholder="Huisnummer*"
              onChange={(input) => handleInputForm(input, 'nr')}
              onBlur={(e) => handleOnBlurRequired(e)}
              value={props.uiStore.candidate.personal.nr}
              name={'number'}
              type={'number'}
              required={true}
            />
          </div>

          <div className={styles.form__row__20}>
            <Input
              placeholder="Bus"
              onChange={(input) => handleInputForm(input, 'bus')}
              value={props.uiStore.candidate.personal.bus}
              name={'bus'}
            />
          </div>
        </div>

        <div className={styles.form__row}>
          <div className={`${styles.form__row__20} margin-right-1x`}>
            <Input
              placeholder="Postcode*"
              onChange={(input) => handleInputForm(input, 'zip')}
              onBlur={(e) => handleOnBlurRequired(e)}
              value={props.uiStore.candidate.personal.zip}
              name={'zip'}
              required={true}
            />
          </div>

          <div className={`${styles.form__row__80}`}>
            <Input
              placeholder="Plaats*"
              onChange={(input) => handleInputForm(input, 'place')}
              onBlur={(e) => handleOnBlurRequired(e)}
              value={props.uiStore.candidate.personal.place}
              name={'city'}
              required={true}
            />
          </div>
        </div>

        <div className={`${styles.form__row} ${styles.form__col__mobile}`}>
          <Input
            placeholder="Rekening nr.* - BE"
            onChange={(input) => handleInputForm(input, 'accountNr')}
            onBlur={(e) => handleOnBlurRequired(e, 'iban')}
            value={props.uiStore.candidate.personal.accountNr}
            name={'accountNr'}
            required={true}
          />

          <select
            className={styles.selectInput}
            name="maritalStatus"
            id="maritalStatus"
            value={
              props.uiStore.candidate.personal.maritalStatus
                ? props.uiStore.candidate.personal.maritalStatus
                : 'placeholder'
            }
            onChange={(input) => handleInputForm(input, 'maritalStatus')}
          >
            <option value="placeholder" disabled>
              Burgerlijke stand
            </option>
            <option value="Ongehuwd">Ongehuwd</option>
            <option value="Wettig gehuwd">Wettig gehuwd</option>
            <option value="Partnerschap">Partnerschap</option>
            <option value="verweduwd na wettig huwelijk">
              verweduwd na wettig huwelijk
            </option>
            <option value="verweduwd na partnerschap">
              verweduwd na partnerschap
            </option>
            <option value="gescheiden na wettig huwelijk">
              gescheiden na wettig huwelijk
            </option>
            <option value="gescheiden na partnerschap">
              gescheiden na partnerschap
            </option>
          </select>
        </div>

        <div className={styles.form__row}>
          <select
            className={styles.selectInput}
            name="kids"
            id="kids"
            defaultValue={
              props.uiStore.candidate.personal.kids
                ? props.uiStore.candidate.personal.kids
                : 'placeholder'
            }
            onChange={(input) => handleInputForm(input, 'kids')}
            onBlur={(e) => handleOnBlurRequired(e)}
          >
            <option value="placeholder" disabled>
              Kinderen ten laste?
            </option>
            <option value="ja">Ja</option>
            <option value="nee">Nee</option>
          </select>

          {props.uiStore.candidate.personal.kids === 'ja' ? (
            <Input
              placeholder="Aantal kinderen ten laste*"
              onChange={(input) => handleInputForm(input, 'kidsAmount')}
              onBlur={(e) => handleOnBlurRequired(e)}
              value={props.uiStore.candidate.personal.kidsAmount}
              name={'kidsAmount'}
              type={'number'}
              required={true}
            />
          ) : null}
        </div>
      </fieldset>
    </form>
  );
};

export default inject(`uiStore`)(observer(PersonalForm));
