import React from "react";

import styles from '../../../containers/mobile.module.scss';

const ScanFront = (props) => {
	return(
		<div>
			<label className={`
					${styles.mobile__button} 
					${!props.imgSourceFront ? styles.mobile__buttonPrimary : styles.mobile__buttonSecondary}
				`} 
				htmlFor="scan"
			>
				{ !props.imgSourceFront ? 'voorkant scannen' : 'opnieuw proberen' }
			</label>
			<input 
				onChange={(e) => props.onHandleUpload(e, 'front')}
				className={styles.mobile__inputDefault} 
				type="file" 
				accept="image/*;capture=camera" 
				id="scan" 
			/>
		</div>
	);
}

export default ScanFront;