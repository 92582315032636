import UiStore from "./UiStore";
import DataStore from "./DataStore";
import ConnectionStore from "./ConnectionStore";

class Store {
  constructor() {
		this.uiStore = new UiStore(this);
		this.dataStore = new DataStore(this);
		this.connectionStore = new ConnectionStore(this);
  }
}

export default new Store();
