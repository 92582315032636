import React from 'react';

import styles from './steps.module.scss';

const Steps = (props) => {

	return (
		<div className={styles.steps}>
			<ul className={styles.steps__list}>
				<li className={`
					${styles.steps__item}
					${props.current === "personal" ? styles.steps__item__active : "" }
				`}>
					persoonlijke gegevens
				</li>
				<li className={`
					${styles.steps__item}
					${props.current === "nationality" ? styles.steps__item__active : "" }
				`}>
					nationaliteit
				</li>
				<li className={`
					${styles.steps__item}
					${props.current === "id" ? styles.steps__item__active : "" }
				`}>
					identiteitskaart
				</li>
				<li className={`
					${styles.steps__item}
					${props.current === "status" ? styles.steps__item__active : "" }
				`}>
					statuut
				</li>
			</ul>
		</div>
	);
}

export default Steps;