import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import Layout from '../components/app/Layout';
import Input from '../components/ui-elements/Input';
import Tooltip from '../components/ui-elements/Tooltip';
import { ROUTES } from '../constants';

import styles from './nationality.module.scss';

import Countries from '../components/app/nationality/Countries';

const Nationality = (props) => {
  const allCountries = toJS(props.dataStore.countries);
  const [displayedCountries, setDisplayedCountries] = useState([]);
  const [filter, setFilter] = useState();
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    checkAuth();
  });

  const checkAuth = () => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }
  };

  const handleShowCountries = (input) => {
    setFilter(input);

    input === ''
      ? setDisplayedCountries([])
      : setDisplayedCountries(
          allCountries.filter(function (country) {
            return (country.translations.nld.common ?? country.name)
              .toLowerCase()
              .startsWith(input.toLowerCase());
          })
        );
  };

  const handleSelectCountry = (country) => {
    setFilter(country.currentTarget.value);
    
    const selected = country.currentTarget.value.toLowerCase();
    props.uiStore.candidate.nationality = selected;
    setValidate(true);
  };

  return props.uiStore.userAuth ? (
    <Layout
      step="nationality"
      previous={ROUTES.personalInformation}
      next={ROUTES.idType}
      nextState={!validate}
    >
      <div className={styles.nationality__wrapper}>
        <div className={styles.nationality__titleWrapper}>
          <h2 className={styles.nationality__title}>Nationaliteit</h2>
          <div className={styles.nationality__tooltip}>
            <Tooltip text="Wij vragen uw nationaliteit zo dat we in de volgende stappen van deze applicatie weten welk type identiteitskaart we mogen verwachten." />
          </div>
        </div>

        <div
          className={`
					${styles.nationality__search}
					${filter ? styles.hasValue : ''}
				`}
        >
          {allCountries.forEach((i) => {
            return <p>{i.name}</p>;
          })}
          <Input
            type="text"
            placeholder="Uw nationaliteit"
            onChange={(e) => handleShowCountries(e)}
            value={filter}
          />
        </div>
        {displayedCountries.length > 0 ? (
          <div className={styles.nationality__list}>
            <Countries
              countries={displayedCountries}
              onSelect={(r) => handleSelectCountry(r)}
              selected={props.uiStore.candidate.nationality}
            />
          </div>
        ) : null}
      </div>
    </Layout>
  ) : null;
};

export default inject(`uiStore`, `dataStore`)(observer(Nationality));
