import React, { useState } from 'react';

import styles from './statusTypes.module.scss';

const IdTypes = (props) => {
	const [selected, setSelected] = useState();

	const handleSelectStatus = e => {
		setSelected(e.currentTarget.value);
		props.onSelect(e.currentTarget.value);
	}
 
	return (
		<ul className={styles.statusTypes}>
			{props.types.map(status => (
				<li key={status}>
					<label className={`
						${styles.status}
						${selected === status || status === props.selected ? styles.active : ''}
					`}>
						<input type="radio" name="statusTypes" value={status} onChange={(e) => handleSelectStatus(e)} selected={status === props.selected ? true : false} />
						<span>{status}</span>
					</label>
				</li>
			))}
		</ul>
	);
}

export default IdTypes;