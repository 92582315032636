import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Dropzone from 'react-dropzone';

import Layout from '../components/app/Layout';

import styles from './student.module.scss';
import { ROUTES } from '../constants';


const Student = (props) => {
  const [files, setFiles] = useState([]);
  const [hours, setHours] = useState();
  const [hoursError, setHoursError] = useState(false);
  const [validate, setValidate] = useState(false);
  const [sizeError, setSizeError] = useState(false);



  useEffect(() => {
    checkAuth();

    if (props.uiStore.candidate.studentHours) {
      setHours(props.uiStore.candidate.studentHours);
    }
  }, [props.uiStore.candidate.studentHours]);

  const checkAuth = () => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }
  };

  const onDrop = (input) => {
    setFiles(input);
    props.uiStore.candidate.studentAtWork = input;

    if (props.uiStore.candidate.studentAtWork) {
      setValidate(true);
    }

    readPdf(input[0]);
  };

  const readPdf = (file) => {
    let reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        props.uiStore.candidate.studentAtWork = reader.result.replace('data:application/pdf;base64,','');
      },
      false
    );

    try {
      reader.readAsDataURL(file);
      setSizeError(false);
    } catch (error) {
      setSizeError(true);
    }
  };

  const onInputHours = (e) => {
    const hours = parseInt(e.currentTarget.value);

    //check if student can work another 8hour day
    if (hours < 8) {
      setHoursError(true);
      setValidate(false);
    } else {
      setHoursError(false);
      setHours(hours);
      setValidate(true);
      props.uiStore.candidate.studentHours = hours;
    }
  };

  return (
    <>
      <Layout
        previous={ROUTES.status}
        next={ROUTES.control}
        nextState={validate && files.length >= 1 && hours ? false : true}
      >
        <div className={styles.student__wrapper}>
          <h2 className={styles.student__title}>Jobstudent</h2>
          <p className={styles.student__text}>
            Om u als jobstudent te werk te stellen hebben we een student@work
            attest nodig alsook het aantal uren dat je nog mag werken.
          </p>

          <div className={styles.student__upload}>
            <h3 className={styles.student__title}>Student@work attest</h3>
            {sizeError ? (
              <p className={styles.student__sizeError}>
                Uw bestand is te groot. Het bestand mag niet groter dan 1.5mb
                zijn.
              </p>
            ) : null}
            <Dropzone
              onDrop={(file) => onDrop(file)}
              accept=".pdf"
              maxSize={1572864}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className={styles.student__upload__section}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p className={styles.student__upload__section__text}>
                      Klik hier om een attest te selecteren <br /> of sleep uw
                      attest hier naartoe. (max. 1.5mb)
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            {files.length > 0 ? (
              <p className={styles.student__upload__section__file}>
                {files[0].name}
              </p>
            ) : null}
          </div>

          <div className={styles.student__hours}>
            <p className={styles.student__title}>
              Ik mag nog{' '}
              <input
                className={styles.student__hours__input}
                onChange={(e) => onInputHours(e)}
                type="number"
                defaultValue={hours ? hours : ''}
              />{' '}
              uren werken als student.
            </p>
            {hoursError ? (
              <p>
                Indien u niet genoeg resterende uren heeft als student, gelieve
                u dan in te schrijven als werkstudent.
              </p>
            ) : null}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`)(observer(Student));
