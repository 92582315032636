import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import Layout from '../components/app/Layout';

import styles from './control.module.scss';
import { ROUTES } from '../constants';

const Control = (props) => {
  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = () => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }
  };

  return (
    <>
      <Layout step="control" previous={ROUTES.status}>
        <div className={styles.control__wrapper}>
          <h2 className={styles.control__title}>Controleer uw gegevens</h2>
          {/* <p className={styles.control__text}>
						Onderaan kunt u al uw ingevulde gegevens nog eens controleren. U kunt deze ook nog aanpassen waar nodig.
					</p> */}
          <div className={styles.control__section}>
            <div className={styles.control__section__row}>
              <div className={styles.control__section__row__item}>
                <div className={styles.control__section__row__item__titleRow}>
                  <h3 className={styles.control__section__row__item__title}>
                    Persoonlijke gegevens
                  </h3>
                  <Link
                    className={styles.control__section__row__item__edit}
                    to={ROUTES.personalInformation}
                  >
                    wijzig
                  </Link>
                </div>

                <p className={styles.control__section__row__item__text}>
                  {props.uiStore.candidate.personal.firstName +
                    ' ' +
                    props.uiStore.candidate.personal.lastName}
                </p>
                <p className={styles.control__section__row__item__text}>
                  {props.uiStore.candidate.personal.street +
                    ' ' +
                    props.uiStore.candidate.personal.nr +
                    (props.uiStore.candidate.personal.bus || '')}
                </p>
                <p className={styles.control__section__row__item__text}>
                  {props.uiStore.candidate.personal.zip +
                    ' ' +
                    props.uiStore.candidate.personal.place}
                </p>
                <p className={styles.control__section__row__item__text}>
                  Rekeningnr.: {props.uiStore.candidate.personal.accountNr}
                </p>
                <p className={styles.control__section__row__item__text}>
                  {props.uiStore.candidate.personal.maritalStatus}
                </p>
                <p className={styles.control__section__row__item__text}>
                  {props.uiStore.candidate.personal.kids === 'ja'
                    ? 'Kinderen'
                    : 'Geen kinderen'}{' '}
                  ten laste
                  {props.uiStore.candidate.personal.kidsAmount
                    ? ': ' + props.uiStore.candidate.personal.kidsAmount
                    : null}
                </p>
              </div>

              <div className={styles.control__section__row__item}>
                <div className={styles.control__section__row__item__titleRow}>
                  <h3 className={styles.control__section__row__item__title}>
                    Contact gegevens
                  </h3>
                  <Link
                    className={styles.control__section__row__item__edit}
                    to={ROUTES.personalInformation}
                  >
                    wijzig
                  </Link>
                </div>
                <p className={styles.control__section__row__item__text}>
                  {props.uiStore.candidate.contact.email}
                </p>
                <p className={styles.control__section__row__item__text}>
                  {props.uiStore.candidate.contact.code +
                    props.uiStore.candidate.contact.phone}
                </p>
              </div>
            </div>
            <div className={styles.control__section__row}>
              <div className={styles.control__section__row__item}>
                <div className={styles.control__section__row__item}>
                  <div className={styles.control__section__row__item__titleRow}>
                    <h3 className={styles.control__section__row__item__title}>
                      Nationaliteit
                    </h3>
                    <Link
                      className={styles.control__section__row__item__edit}
                      to={ROUTES.nationality}
                    >
                      wijzig
                    </Link>
                  </div>
                  <p className={styles.control__section__row__item__text}>
                    {props.uiStore.candidate.nationality}
                  </p>
                </div>

                <div className={styles.control__section__row__item}>
                  <div className={styles.control__section__row__item__titleRow}>
                    <h3 className={styles.control__section__row__item__title}>
                      Statuut
                    </h3>
                    <Link
                      className={styles.control__section__row__item__edit}
                      to={ROUTES.status}
                    >
                      wijzig
                    </Link>
                  </div>
                  <p className={styles.control__section__row__item__text}>
                    {props.uiStore.candidate.status}
                    {props.uiStore.candidate.studentHours
                      ? ' ' + props.uiStore.candidate.studentHours + 'uur'
                      : null}
                  </p>
                </div>
              </div>

              <div className={styles.control__section__row__item}>
                <div className={styles.control__section__row__item__titleRow}>
                  <h3 className={styles.control__section__row__item__title}>
                    Identiteitskaart
                  </h3>
                  <Link
                    className={styles.control__section__row__item__edit}
                    to={ROUTES.idType}
                  >
                    wijzig
                  </Link>
                </div>
                <div className={styles.control__section__row__item__previews}>
                  {props.uiStore.candidate.idImages ? (
                    props.uiStore.candidate.idImages.map((src) => (
                      <div
                        key={src}
                        className={styles.control__section__row__item__card}
                      >
                        <img
                          className={
                            styles.control__section__row__item__card__preview
                          }
                          src={src}
                          width="200"
                          alt=""
                        />
                      </div>
                    ))
                  ) : (
                    <p>We hebben geen afbeeldingen gevonden.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`)(observer(Control));
