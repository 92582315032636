import React from 'react';

import styles from './button.module.scss';

const Button = (props) => {
 
	return (
		<button className={`
				${styles.button}
				${props.type === "primary" ? styles.buttonPrimary : "" }
				${props.type === "secondary" ? styles.buttonSecondary : "" }
				${props.classes}
			`}
			onClick={props.onClick}
			disabled={props.state ? props.state : false}
		>
			{props.text}
		</button>		
	);
}

export default Button;