import React from "react";

import styles from './tooltip.module.scss';

const Tooltip = (props) => {

	return (
		<div className={styles.tooltip}>
			<div className={styles.tooltip__trigger}>
				<img className={styles.tooltip__trigger__image} src="assets/tooltip.svg" alt="Cirkel with questionmark"/>
			</div>
			<div className={styles.tooltip__wrapper}>
				<p className={styles.tooltip__wrapper__text}>{props.text}</p>
			</div>
		</div>
	);
}

export default Tooltip;