import React, { useState } from 'react';

import styles from './idTypes.module.scss';

const IdTypes = (props) => {
	const [selected, setSelected] = useState();

	const handleSelectType = e => {
		setSelected(e.currentTarget.value);
		props.onSelect(e.currentTarget.value);
	}
 
	return (
		<ul className={styles.types}>
			{ props.nationality === "belgium" ? (
				<li key={"belgië"}>
					<label className={`
						${styles.type}
						${selected === "belgië" || "belgië" === props.selected ? styles.active : ''}
					`}>
						<input type="radio" name="types" value={"belgië"} onChange={(e) => handleSelectType(e)} selected={"belgë" === props.selected ? true : false} />
						<span>{"Belgische kaart"}</span>
					</label>
				</li>
			):null }
			
			{props.data.idTypes.map(type => (
				<li key={type}>
					<label className={`
						${styles.type}
						${selected === type || type === props.selected ? styles.active : ''}
					`}>
						<input type="radio" name="types" value={type} onChange={(e) => handleSelectType(e)} selected={type === props.selected ? true : false} />
						<span>{type}</span>
					</label>
				</li>
			))}
		</ul>
	);
}

export default IdTypes;