import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import Nav from '../components/landingpage/Nav';
import Header from '../components/landingpage/Header';
import AboutCopy from '../components/landingpage/AboutCopy';
import Steps from '../components/landingpage/Steps';
import Questions from '../components/landingpage/Questions';
import Footer from '../components/landingpage/Footer';

const Home = (props) => {
  useEffect(() => {
    props.uiStore.clearData();
  }, [props.uiStore]);

  return (
    <>
      <Nav />
      <Header />
      <AboutCopy />
      <Steps />
      <Questions />
      <Footer />
    </>
  );
};

export default inject(`uiStore`)(observer(Home));
