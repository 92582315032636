import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import Layout from '../components/app/Layout';
import types from '../constants/idTypes.json';
import IdTypes from '../components/app/id/IdTypes';
import invalidTypes from '../constants/invalidTypes.json';

import styles from './idType.module.scss';
import { ROUTES } from '../constants';

const IdType = (props) => {
  const [invalidType, setInvalidType] = useState(false);
  const [validate, setValidate] = useState(false);

  const onSelectType = (r) => {
    props.uiStore.candidate.idType = r;

    if (invalidTypes.invalidTypes.includes(r)) {
      setInvalidType(true);
    } else {
      setInvalidType(false);
    }

    setValidate(true);
  };

  useEffect(() => {
    checkAuth();
    props.uiStore.step = Object.keys(ROUTES).indexOf('idType');

    if (props.uiStore.candidate.idType) {
      setValidate(true);
    }
  }, []);

  const checkAuth = () => {
    if (!props.uiStore.userAuth) {
      props.history.push('/enter');
    }
  };

  return (
    <>
      <Layout
        step="id"
        previous={ROUTES.nationality}
        next={invalidType ? ROUTES.invalidType : ROUTES.uploadChoice}
        nextState={!validate}
      >
        <div className={styles.id__wrapper}>
          <h2 className={styles.id__title}>
            Selecteer uw{' '}
            <span className="highlight">type identiteitskaart</span>
          </h2>
          <p className={styles.id__text}>
            U kunt het type van uw identiteitskaart terugvinden op de kaart
            zelf. Dit wordt duidelijk weergegeven, vaak bovenaan. Neem contact
            met ons op indien u hier bij problemen ondervindt (056 22 10 35).
          </p>
          <IdTypes
            nationality={props.uiStore.candidate.nationality}
            data={types}
            onSelect={(r) => onSelectType(r)}
            selected={props.uiStore.candidate.idType}
          />
        </div>
      </Layout>
    </>
  );
};

export default inject(`uiStore`)(observer(IdType));
