import { decorate, observable } from 'mobx';
import io from 'socket.io-client';
import Api from '../api';

class UiStore {
  step = 0;

  candidate = {
    personal: {},
    contact: {},
    nationality: '',
  };

  userAuth = false;
  userId = '';
  client = false;
  currentClient = '';

  connected = false;
  roomId = null;
  socket = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.api = new Api(`mail`);
    this.connectSocket();
  }

  checkClient = (value) => {
    const result = this.rootStore.dataStore.pwdsData.find((client) => client.pass === value);

    if (result) {
      this.userAuth = true;
      this.client = true;
      this.currentClient = result.client;
    }
  };

  connectSocket = () => {
    let port;
    if (window.location.hostname === `sea-turtle-app-5y27a.ondigitalocean.app`) {
      port = `:4000`;
    } else {
      port = ``;
    }

    this.socket = io(
      // `${window.location.protocol}//${window.location.hostname}${port}`
      `https://sea-turtle-app-5y27a.ondigitalocean.app/${port}`
    );

    this.connected = true;
  };

  joinSocket = (id) => {
    console.log('joining ', id);
    this.socket.emit('join', id);
  };

  sendImages = async (front, back, id) => {
    const data = [front, back, id];

    this.socket.emit(`sendImages`, data);
  };

  sendMail = () => {
    const message = `
				<h2>Nieuwe payroll inschrijving van ${this.candidate.personal.firstName} ${
      this.candidate.personal.lastName
    } voor ${this.currentClient}</h2>
				<h3>Persoonlijke gegevens</h3>
				<p style="font-size:16px;">Voornaam: ${this.candidate.personal.firstName}</p>
				<p style="font-size:16px;">Familienaam: ${this.candidate.personal.lastName}</p>
				<p style="font-size:16px;">Adres: ${this.candidate.personal.street} ${
      this.candidate.personal.nr
    } ${this.candidate.personal.bus ? this.candidate.personal.bus : ''},</br> ${
      this.candidate.personal.place
    } ${this.candidate.personal.zip}</p>
				<p style="font-size:16px;">Rekeningnr.: ${this.candidate.personal.accountNr}</p>
				<p style="font-size:16px;">Burgerlijke stand: ${
          this.candidate.personal.maritalStatus
        }</p>
				<p style="font-size:16px;">Kinderen ten laste: ${
          this.candidate.personal.kids
        }</p>
				<p style="font-size:16px;">Aantal kinderen ten laste: ${
          this.candidate.personal.kidsAmount
        }</p>
				<h3>Contact gegevens</h3>
				<p style="font-size:16px;">E-mail: ${this.candidate.contact.email}</p>
				<p style="font-size:16px;">Telefoon: ${this.candidate.contact.phone}</p>
				<h3>Nationaliteit</h3>
				<p style="font-size:16px;">Nationaliteit: ${this.candidate.nationality}</p>
				<h3>Statuut</h3>
				<p style="font-size:16px;">Statuut: ${this.candidate.status}</p>
				${
          this.candidate.studentHours
            ? `<p style="font-size:16px;">Resterende uren: ${this.candidate.studentHours}</p>
					<embed width="750" height="1061" src="${this.candidate.studentAtWork}"/>
					`
            : ''
        }
				<h3>Identiteitskaart</h3>
				<img src="${
          this.candidate.idImages[0]
        }" style="margin-bottom:10px;" width="500" alt="id kaart voorkant"/></br>
				<img src="${this.candidate.idImages[1]}" width="500" alt="id kaart achterkant"/>
			`;

    const values = {
      email: 'kortrijk@thejobstore.be',
      subject: `Payroll inschrijving: ${this.candidate.personal.firstName}  ${this.candidate.personal.lastName} voor ${this.currentClient}`,
      pdfName: `${this.candidate.personal.firstName}  ${this.candidate.personal.lastName} - studentAtWork.pdf`,
      pdf: this.candidate.studentAtWork ? this.candidate.studentAtWork : null
    };

    const newRequest = {
      data: values,
      output: message,
    };

    this.api.create(newRequest);
  };

  clearData = () => {
    this.candidate = {
      personal: {},
      contact: {},
      nationality: '',
    };

    this.userAuth = false;
    this.currentClient = '';
  };
}

decorate(UiStore, {
  step: observable,
  candidate: observable,
  clients: observable,
  client: observable,
  userAuth: observable,
  currentClient: observable,
  connected: observable,
  socket: observable,
  roomId: observable,
});

export default UiStore;
