import { decorate, observable, action } from 'mobx';
import countriesv2 from '../constants/countries.json';
import Airtable from 'airtable';

class DataStore {
  countries = null;
  uniqueId = '';
  pwdsData = [];

  
  base = new Airtable({apiKey: 'pat3UiFBSSMRnOCZJ.ff926b97ad19f28dfffcdd98348421f9f60c4d8c61f3bc507817908e8ebc6e1c'}).base('appOHN8fh52tL3dtt');
  table = this.base('TJS Klanten');

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getCountries = async () => {
    this.countries = countriesv2;
  };

  getPwds = async () => {
    const records = await this.table.select().firstPage();

    records.forEach(r => r.fields.Klant ? this.pwdsData.push({client: r.fields.Klant, pass: r.fields.pass}) : null)
  }
}

decorate(DataStore, {
  countries: observable,
  uniqueId: observable,
  pwdsData: observable,
  getCountries: action,
  getPwds: action
});

export default DataStore;
