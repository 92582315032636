import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import Layout from '../components/app/Layout';
import PersonalFrom from '../components/app/personal/PersonalForm';
import ContactInfoForm from '../components/app/personal/ContactInfoForm';

import styles from './personal.module.scss';
import { ROUTES } from '../constants';

const Personal = (props) => {
  const [codes, setCodes] = useState([]);
  const [validatePersonal, setValidatePersonal] = useState(false);
  const [validateContact, setValidateContact] = useState(false);

  useEffect(() => {
    if (!props.uiStore.userAuth || !props.dataStore.countries.length) {
      props.history.push('/enter');
    }

    const countryCodes = [];
    props.dataStore.countries.map((country) => {
      if (country.callingCodes[0] !== '') {
        const codeValue = `${country.cca3.toLowerCase()} ${
          country.idd.root + country.idd.suffixes[0]
        }`;
        countryCodes.push(codeValue);
      }
    });

    setCodes(countryCodes);
  }, [props.dataStore.countries, props.history, props.uiStore.userAuth]);

  return (
    <Layout
      step="personal"
      previous={ROUTES.enter}
      next={ROUTES.nationality}
      nextState={validatePersonal && validateContact ? false : true}
    >
      <div className={styles.info__wrapper}>
        <div className={styles.personal__formwrapper}>
          <PersonalFrom onValidate={(value) => setValidatePersonal(value)} />
        </div>

        <div className={styles.personal__formwrapper}>
          <ContactInfoForm
            codes={codes}
            onValidate={(value) => setValidateContact(value)}
          />
        </div>
      </div>
    </Layout>
  );
};

export default inject(`dataStore`, `uiStore`)(observer(Personal));
